import axios from '../../config/axios';
import { useNavigate } from 'react-router-dom';
import SidebarButton from '../atom/SidebarButton';

function Sidebar() {
  let navigate = useNavigate();

  const logout = async () => {
    try {
      const res = await axios.delete('/api/auth/logout');
      console.log('res', res);
      navigate('/login');
    } catch (error) {
      console.error(error.response);
    }
  };

  return (
    <div className="h-full min-h-screen w-16 border-r bg-white">
      <div className="flex h-full flex-col">
        <div className="p-2">
          {/* <div className="flex aspect-square items-center justify-center rounded-lg bg-blue-900 text-4xl font-bold text-white"></div> */}
          <img
            src="/logo-pu.jpeg"
            alt=""
            className="w-full rounded shadow-md"
          />
          {/* <img
          src="https://graph.facebook.com/499317901904761/picture"
          alt=""
          className="w-full rounded shadow-md"
        /> */}
        </div>
        <div className="flex-1 py-2">
          <div className="flex h-full flex-col items-center justify-between">
            <div>
              <SidebarButton
                title="Dashboard"
                _onClick={() => {
                  navigate('/');
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
                  />
                </svg>
              </SidebarButton>
              <SidebarButton
                title="Data Telemetri"
                _onClick={() => {
                  navigate('/telemetri/awlr');
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </SidebarButton>
              <SidebarButton
                title="Peta"
                _onClick={() => {
                  navigate('/map');
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
                  />
                </svg>
              </SidebarButton>

              <SidebarButton
                title="Data Teknis"
                _onClick={() => {
                  navigate('/data-teknis');
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                  />
                </svg>
              </SidebarButton>
              <SidebarButton
                title="Users"
                _onClick={() => {
                  navigate('/users');
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                  />
                </svg>
              </SidebarButton>
            </div>
            <div>
              <SidebarButton title="Logout" _onClick={() => logout()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-red-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
              </SidebarButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
