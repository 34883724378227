/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
// import useSocket from '../hook/useSocket';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from '../config/axios';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function DataAwlrAll() {
  // const { label, dataset } = useSocket();
  const [dataLabel, setDataLabel] = useState([]);
  const [tinggiMukaAir, setTinggiMukaAir] = useState([]);
  const [humadity, setHumadity] = useState([]);
  const [temprature, setTemprature] = useState([]);
  const [batteray, setBatteray] = useState([]);

  useEffect(() => {
    fetchDataAwlr();
  }, []);

  // useEffect(() => {
  //   console.log('data label', dataLabel);
  //   let dl = [...dataLabel, ...label];
  //   if (dl.length > 5) {
  //     dl.shift();
  //   }
  //   setDataLabel(dl);
  // }, [label]);

  // useEffect(() => {
  //   console.log('data set', tinggiMukaAir);
  //   if (dataset.length > 0) {
  //     let dataSetJson = dataset[0];
  //     console.log('data set inside if', dataSetJson);
  //     let tma = [...tinggiMukaAir, dataSetJson[0].data];
  //     if (tma.length > 5) {
  //       tma.shift();
  //     }
  //     setTinggiMukaAir(tma);

  //     let hmdt = [...humadity, dataSetJson[1].data];
  //     if (hmdt.length > 5) {
  //       hmdt.shift();
  //     }
  //     setHumadity(hmdt);

  //     let tmpt = [...temprature, dataSetJson[2].data];
  //     if (tmpt.length > 5) {
  //       tmpt.shift();
  //     }
  //     setTemprature(tmpt);

  //     let btry = [...batteray, dataSetJson[3].data];
  //     if (btry.length > 5) {
  //       btry.shift();
  //     }
  //     setBatteray(btry);
  //   }
  // }, [dataset]);

  const fetchDataAwlr = async () => {
    try {
      const res = await axios.get('/api/telemetri/awlr');
      console.log('res', res.data.data);
      let awlr = res.data.data;
      let dlabel = [];
      let dset = [];
      let tma = [];
      let mhumidity = [];
      let temp = [];
      let btr = [];
      for (let index = awlr.length - 1; index >= 0; index--) {
        dlabel = [...dlabel, awlr[index].waktu];

        // setDataLabel([...dataLabel, awlr[index].waktu]);
        let set = JSON.parse(awlr[index].data);

        dset = [...dset, set[0].data];
        tma = [...tma, set[0].data];
        mhumidity = [...mhumidity, set[1].data];
        temp = [...temp, set[2].data];
        btr = [...btr, set[3].data];
      }
      console.log(dlabel, dset);
      setDataLabel(dlabel);
      // setDataDataset(dset);
      setTinggiMukaAir(tma);
      setHumadity(mhumidity);
      setTemprature(temp);
      setBatteray(btr);
    } catch (error) {
      console.error(error.response);
    }
  };

  return (
    <div className="flex">
      <div className="w-full">
        <div className="border-b bg-white p-4 font-bold uppercase">
          Data Telemetri
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="rounded-md border bg-white p-4">
              <Line
                data={{
                  labels: [...dataLabel],
                  datasets: [
                    {
                      id: 1,
                      label: 'Tinggi Muka Air',
                      data: [...tinggiMukaAir],
                      borderColor: 'rgb(53, 162, 235)',
                      backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                  ],
                }}
              />
            </div>
            <div className="rounded-md border bg-white p-4">
              <Line
                data={{
                  labels: [...dataLabel],
                  datasets: [
                    {
                      id: 2,
                      label: 'Humadity',
                      data: [...humadity],
                      borderColor: 'rgb(75, 192, 192)',
                      backgroundColor: 'rgb(75, 192, 192, 0.5)',
                    },
                  ],
                }}
              />
            </div>
            <div className="rounded-md border bg-white p-4">
              <Line
                data={{
                  labels: [...dataLabel],
                  datasets: [
                    {
                      id: 3,
                      label: 'Temprature',
                      data: [...temprature],
                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                  ],
                }}
              />
            </div>
            <div className="rounded-md border bg-white p-4">
              <Line
                data={{
                  labels: [...dataLabel],
                  datasets: [
                    {
                      id: 3,
                      label: 'Batteray',
                      data: [...batteray],
                      borderColor: 'rgba(255, 159, 64)',
                      backgroundColor: 'rgba(255, 159, 64, 0.5)',
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        {/* <div className="mx-auto h-full w-full max-w-7xl flex-1 p-4">
          <div className="rounded-md border bg-white p-4">
            <Line
              data={{
                labels: [...dataLabel],
                datasets: [
                  {
                    id: 1,
                    label: 'Tinggi Muka Air',
                    data: [...tinggiMukaAir],
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                  },
                  {
                    id: 2,
                    label: 'Humadity',
                    data: [...humadity],
                    borderColor: 'rgb(75, 192, 192)',
                    backgroundColor: 'rgb(75, 192, 192, 0.5)',
                  },
                  {
                    id: 3,
                    label: 'Temprature',
                    data: [...temprature],
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  },
                ],
              }}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default DataAwlrAll;
