/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
// import useSocket from '../hook/useSocket';
import { getDatabase, onValue, ref } from "firebase/database";
import { app } from "../config/firebase";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "../config/axios";
import ModalNotification from "../component/molecul/ModalNotification";
import ChartData from "../component/molecul/ChartData";
import { CHART_COLORS } from "../config/utils";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import titikKumpul from "../data/titikkumpul.json";
import titikPengunsian from "../data/titik_pengungsian.json";
import L from "leaflet";
import ModalInstrument from "../component/molecul/ModalInstrument";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  // const { label, dataset } = useSocket();
  const [dataLabel, setDataLabel] = useState([]);
  const [tinggiMukaAir, setTinggiMukaAir] = useState([]);
  const [humadity, setHumadity] = useState([]);
  const [temprature, setTemprature] = useState([]);
  const [batteray, setBatteray] = useState([]);
  const [status, setStatus] = useState(null);
  const [statusColor, setStatusColor] = useState("bg-blue-500");

  const [tanggal, setTanggal] = useState("");
  const [tma, setTma] = useState("");

  const [notifTma, setNotifTma] = useState(0);
  const [notifStatus, setNotifStatus] = useState("");
  const [notifQStatus, setNotifQstatus] = useState("");

  const [center, setCenter] = useState([-8.742733, 116.848152]);
  const [activeInstr, setActiveInstr] = useState(0);

  const instrument = [
    "FP09",
    "FP10",
    "FP11",
    "FP12",
    "FP13",
    "FP14",
    "FP15",
    "FP16",
    "EP10",
    "EP11",
    "EP12",
    "EP13",
    "EP14",
    "EP15",
    "EP16",
    "EP17",
    "EP18",
  ];

  const modalNotifRef = useRef();
  const modalInstrumentRef = useRef();

  const db = getDatabase(app);

  let currrentDate = new Date();
  let tWaktu =
    currrentDate.getFullYear() +
    "-" +
    (currrentDate.getMonth() + 1) +
    "-" +
    currrentDate.getDate();

  useEffect(() => {
    const starCountRef = ref(db, "telemetri/awlr");
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      console.log("firebase data telemetri", data);

      setTanggal(data.waktu);
      setTma(data.data_terakhir[0].data);
      // setTmaAwlrOrong(data.orong.debit.toFixed(2));
      // setTmaAwlrBlencong(data.blencong.debit.toFixed(2));
    });
  }, []);

  useEffect(() => {
    handleStatus();
    handelSetStatusColor();
  }, [tma]);

  useEffect(() => {
    console.log("q status", notifQStatus);
  }, [notifQStatus]);

  // useEffect(() => {
  //   fetchDataAwlr();
  // }, []);

  // useEffect(() => {
  //   // console.log('data label', dataLabel);
  //   console.log('tinggi muka air', tinggiMukaAir);
  //   let dl = [...dataLabel, ...label];
  //   if (dl.length > 5) {
  //     dl.shift();
  //   }
  //   setDataLabel(dl);
  // }, [label]);

  // useEffect(() => {
  //   console.log('data set', tinggiMukaAir);
  //   if (dataset.length > 0) {
  //     let dataSetJson = dataset[0];
  //     console.log('data set inside if', dataSetJson);
  //     let tma = [...tinggiMukaAir, dataSetJson[0].data];
  //     if (tma.length > 5) {
  //       tma.shift();
  //     }
  //     setTinggiMukaAir(tma);

  //     let hmdt = [...humadity, dataSetJson[1].data];
  //     if (hmdt.length > 5) {
  //       hmdt.shift();
  //     }
  //     setHumadity(hmdt);

  //     let tmpt = [...temprature, dataSetJson[2].data];
  //     if (tmpt.length > 5) {
  //       tmpt.shift();
  //     }
  //     setTemprature(tmpt);

  //     let btry = [...batteray, dataSetJson[3].data];
  //     if (btry.length > 5) {
  //       btry.shift();
  //     }
  //     setBatteray(btry);
  //   }
  // }, [dataset]);

  const fetchDataAwlr = async () => {
    try {
      const res = await axios.get("/api/telemetri/awlr");
      console.log("res", res.data.data);
      let awlr = res.data.data;
      let dlabel = [];
      let dset = [];
      let tma = [];
      let mhumidity = [];
      let temp = [];
      let btr = [];
      for (let index = awlr.length - 1; index >= 0; index--) {
        dlabel = [...dlabel, awlr[index].waktu];

        // setDataLabel([...dataLabel, awlr[index].waktu]);
        let set = JSON.parse(awlr[index].data);

        dset = [...dset, set[0].data];
        tma = [...tma, set[0].data];
        mhumidity = [...mhumidity, set[1].data];
        temp = [...temp, set[2].data];
        btr = [...btr, set[3].data];
      }
      console.log(dlabel, dset);
      setDataLabel(dlabel);
      // setDataDataset(dset);
      setTinggiMukaAir(tma);
      setHumadity(mhumidity);
      setTemprature(temp);
      setBatteray(btr);
    } catch (error) {
      console.error(error.response);
    }
  };

  const handleStatus = () => {
    if (tma <= 117.0) {
      setStatus("Status Normal");
    } else if (tma > 117.0 && tma <= 118.25) {
      setStatus("Status Waspada 1");
    } else if (tma > 118.25 && tma <= 119.0) {
      setStatus("Status Waspada 2");
    } else if (tma > 119.0 && tma <= 119.25) {
      setStatus("Status Siaga");
    } else if (tma > 119.25) {
      setStatus("Status Awas");
    }
  };

  const getStatus = (_tma) => {
    if (_tma <= 117.0) {
      return "Status Normal";
    } else if (_tma > 117.0 && _tma <= 118.25) {
      return "Status Waspada 1";
    } else if (_tma > 118.25 && _tma <= 119.0) {
      return "Status Waspada 2";
    } else if (_tma > 119.0 && _tma <= 119.25) {
      return "Status Siaga";
    } else if (_tma > 119.25) {
      return "Status Awas";
    }
  };

  const handelSetStatusColor = (_tma) => {
    if (tma <= 117.0) {
      setStatusColor("bg-blue-500");
    } else if (tma > 117.0 && tma <= 118.25) {
      setStatusColor("bg-green-500");
    } else if (tma > 118.25 && tma <= 119.0) {
      setStatusColor("bg-yellow-500");
    } else if (tma > 119.0 && tma <= 119.25) {
      setStatusColor("bg-orange-500");
    } else if (tma > 119.25) {
      setStatusColor("bg-red-500");
    } else {
      setStatusColor("bg-blue-500");
    }
  };

  const openModalNotif = (stat, tm, qstat) => {
    modalNotifRef.current.openModal(stat, tm, qstat);
  };

  const refreshToken = async () => {
    try {
      const res = await axios.get("/api/auth/refresh");
      console.log("res", res);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex min-h-screen w-full flex-col">
      <div className="border-b bg-white p-4 font-bold uppercase">Dashboard</div>
      <div className="mx-auto h-full w-full flex-1 p-4 md:w-full">
        <div className="grid h-full w-full grid-cols-5 gap-4">
          <div className={`col-span-3 rounded-lg p-8 ${statusColor}`}>
            <div className="flex h-full w-full justify-between">
              <div className="flex flex-col justify-between">
                <div className="text-lg font-medium text-white">
                  Tinggi muka air tanggal : {tanggal}
                </div>
                <div className="flex items-end gap-3">
                  <div className="text-8xl font-bold text-white">{tma}</div>
                  <div className="mb-2 font-semibold text-white">MDPL</div>
                </div>
                <div>
                  <div
                    className={`inline-block rounded-full px-6 py-3 text-2xl font-bold text-white ${statusColor}`}
                  >
                    {status}
                  </div>
                </div>
              </div>
              <div className="flex h-full items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                  className="h-40 w-40 text-white"
                  fill="currentColor"
                >
                  <path d="M112 0C85.5 0 64 21.5 64 48V256H48c-26.5 0-48 21.5-48 48v96c0 8 2 15.6 5.4 22.2c3.8-1.7 7.8-3.1 12-4.1c13.1-3.1 26.7-9.8 37.3-18.6c22.2-18.7 54.3-20.1 78.1-3.4c18 12.4 40.1 20.3 59.2 20.3c21.1 0 42-8.5 59.2-20.3c22.1-15.5 51.6-15.5 73.7 0c18.4 12.7 39.6 20.3 59.2 20.3c19 0 41.2-7.9 59.2-20.3c23.8-16.7 55.8-15.3 78.1 3.4c10.6 8.8 24.2 15.6 37.3 18.6c4.2 1 8.2 2.4 12 4.1C574 415.6 576 408 576 400V304c0-26.5-21.5-48-48-48H480l0-146.7 25.4 25.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-80-80c-12.5-12.5-32.8-12.5-45.3 0l-80 80c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 109.3 416 256H288V48c0-26.5-21.5-48-48-48H112zM306.5 421.9c-11.1-7.9-25.9-7.9-37 0C247 437.4 219.5 448 192 448c-26.9 0-55.3-10.8-77.4-26.1l0 0c-11.9-8.5-28.1-7.8-39.2 1.7c-14.4 11.9-32.5 21-50.6 25.2c-17.2 4-27.9 21.2-23.9 38.4s21.2 27.9 38.4 23.9c24.5-5.7 44.9-16.5 58.2-25C126.5 501.7 159 512 192 512c31.9 0 60.6-9.9 80.4-18.9c5.8-2.7 11.1-5.3 15.6-7.7c4.5 2.4 9.7 5.1 15.6 7.7c19.8 9 48.6 18.9 80.4 18.9c33 0 65.5-10.3 94.5-25.8c13.4 8.4 33.7 19.3 58.2 25c17.2 4 34.4-6.7 38.4-23.9s-6.7-34.4-23.9-38.4c-18.1-4.2-36.2-13.3-50.6-25.2c-11.1-9.4-27.3-10.1-39.2-1.7l0 0C439.4 437.2 410.9 448 384 448c-27.5 0-55-10.6-77.5-26.1z" />
                </svg>
              </div>
            </div>
          </div>

          {/* <div>
            <div className="overflow-hidden rounded-md border bg-white">
              <div className="border-b bg-white p-4 font-bold uppercase">
                Status Kondisi Banjir
              </div>
              <div className="flex items-center justify-center p-4">
                <div className="relative flex items-center justify-center p-12">
                  <div className="h-28 w-28 animate-ping rounded-full bg-green-400 p-8"></div>
                  <div className="absolute rounded-full bg-green-300 p-8">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-16 w-16 animate-none text-green-800"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                      />
                    </svg>
                  </div>
                </div>

                <div className="ml-8 flex-1">
                  <div className="flex h-full items-center">
                    <div>
                      <div className="mt-1 text-5xl font-extrabold uppercase text-green-500">
                        Waspada
                      </div>
                      <div className="mt-1 text-lg text-gray-500">
                        Tinggi muka air saat ini{' '}
                        <span className="font-extrabold text-black">
                          {tinggiMukaAir.length > 0 &&
                            tinggiMukaAir[tinggiMukaAir.length - 1]}{' '}
                          Mdpl
                        </span>
                      </div>

                      <div className="mt-8 grid grid-cols-6 gap-3">
                        <div className="col-span-6 sm:col-span-4">
                          <select
                            id="status"
                            name="status"
                            autoComplete="status"
                            className=" block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option></option>
                            <option value="Kondisi Aman">Kondisi Aman</option>
                            <option value="Kondisi Kurang Aman">
                              Kondisi Kurang Aman
                            </option>
                            <option value="Waspada">Waspada</option>
                            <option value="Siaga">Siaga</option>
                            <option value="Awas">Awas</option>
                          </select>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            onClick={() =>
                              openModalNotif(
                                status,
                                tinggiMukaAir[tinggiMukaAir.length - 1]
                              )
                            }
                          >
                            Kirim
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="col-span-2 overflow-hidden rounded-lg border bg-white">
            <div className="flex h-full flex-col">
              <div className="border-b bg-white p-4 font-bold uppercase">
                Form Manual Tinggi Muka Air
              </div>
              <div className="flex h-full items-center justify-center p-4">
                {/* <div className="p-10">
                  

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    className="h-32 w-32 text-blue-600"
                    fill="currentColor"
                    // viewBox="0 0 24 24"
                    stroke="currentColor"
                    // strokeWidth={10}
                  >
                    <path d="M549.8 237.5c-31.23-5.719-46.84-20.06-47.13-20.31C490.4 205 470.3 205.1 457.7 216.8c-1 .9375-25.14 23-73.73 23s-72.73-22.06-73.38-22.62C298.4 204.9 278.3 205.1 265.7 216.8c-1 .9375-25.14 23-73.73 23S119.3 217.8 118.6 217.2C106.4 204.9 86.35 205 73.74 216.9C73.09 217.4 57.48 231.8 26.24 237.5c-17.38 3.188-28.89 19.84-25.72 37.22c3.188 17.38 19.78 29.09 37.25 25.72C63.1 295.8 82.49 287.1 95.96 279.2c19.5 11.53 51.47 24.68 96.04 24.68c44.55 0 76.49-13.12 96-24.65c19.52 11.53 51.45 24.59 96 24.59c44.58 0 76.55-13.09 96.05-24.62c13.47 7.938 32.86 16.62 58.19 21.25c17.56 3.375 34.06-8.344 37.25-25.72C578.7 257.4 567.2 240.7 549.8 237.5zM549.8 381.7c-31.23-5.719-46.84-20.06-47.13-20.31c-12.22-12.19-32.31-12.12-44.91-.375C456.7 361.9 432.6 384 384 384s-72.73-22.06-73.38-22.62c-12.22-12.25-32.3-12.12-44.89-.375C264.7 361.9 240.6 384 192 384s-72.73-22.06-73.38-22.62c-12.22-12.25-32.28-12.16-44.89-.3438c-.6562 .5938-16.27 14.94-47.5 20.66c-17.38 3.188-28.89 19.84-25.72 37.22C3.713 436.3 20.31 448 37.78 444.6C63.1 440 82.49 431.3 95.96 423.4c19.5 11.53 51.51 24.62 96.08 24.62c44.55 0 76.45-13.06 95.96-24.59C307.5 434.9 339.5 448 384.1 448c44.58 0 76.5-13.09 95.1-24.62c13.47 7.938 32.86 16.62 58.19 21.25C555.8 448 572.3 436.3 575.5 418.9C578.7 401.5 567.2 384.9 549.8 381.7zM37.78 156.4c25.33-4.625 44.72-13.31 58.19-21.25c19.5 11.53 51.47 24.68 96.04 24.68c44.55 0 76.49-13.12 96-24.65c19.52 11.53 51.45 24.59 96 24.59c44.58 0 76.55-13.09 96.05-24.62c13.47 7.938 32.86 16.62 58.19 21.25c17.56 3.375 34.06-8.344 37.25-25.72c3.172-17.38-8.344-34.03-25.72-37.22c-31.23-5.719-46.84-20.06-47.13-20.31c-12.22-12.19-32.31-12.12-44.91-.375c-1 .9375-25.14 23-73.73 23s-72.73-22.06-73.38-22.62c-12.22-12.25-32.3-12.12-44.89-.375c-1 .9375-25.14 23-73.73 23S119.3 73.76 118.6 73.2C106.4 60.95 86.35 61.04 73.74 72.85C73.09 73.45 57.48 87.79 26.24 93.51c-17.38 3.188-28.89 19.84-25.72 37.22C3.713 148.1 20.31 159.8 37.78 156.4z" />
                  </svg>
                </div> */}
                <div className="h-full flex-1">
                  <div className="flex h-full w-full items-center">
                    <div className="w-full">
                      {/* <div className="mt-1 text-5xl font-extrabold uppercase text-green-500">
                      Waspada
                    </div> */}
                      {/* <div className="mt-1 text-lg text-gray-500">
                        Ketinggian muka air saat ini
                      </div> */}

                      <div className="grid grid-cols-6 gap-3">
                        {/* <div className="col-span-6 sm:col-span-4">
                          <input
                            id="status"
                            name="status"
                            autoComplete="status"
                            className=" block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            onChange={(e) => {}}
                          />
                        </div> */}
                        <div className="col-span-6 sm:col-span-6">
                          <label
                            htmlFor="email-address"
                            className="block text-base font-medium text-gray-900"
                          >
                            Ketinggian muka air saat ini
                          </label>
                          <input
                            type="text"
                            name="email-address"
                            id="email-address"
                            autoComplete="email"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            onChange={(e) => {
                              setNotifTma(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-span-6 mt-4 sm:col-span-6">
                          <fieldset>
                            <legend className="sr-only">
                              Perkiraan Status Genangan
                            </legend>
                            <div
                              className="text-base font-medium text-gray-900"
                              aria-hidden="true"
                            >
                              Perkiraan Status Genangan
                            </div>
                            <div className="mt-4 flex w-full gap-6">
                              <div className="flex items-start">
                                <div className="flex h-5 items-center">
                                  <input
                                    id="tidakada"
                                    name="qstatus"
                                    type="radio"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    value=""
                                    onChange={(e) => {
                                      setNotifQstatus(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="tidakada"
                                    className="font-medium text-gray-700"
                                  >
                                    Tidak Ada
                                  </label>
                                </div>
                              </div>
                              <div className="flex items-start">
                                <div className="flex h-5 items-center">
                                  <input
                                    id="q2"
                                    name="qstatus"
                                    type="radio"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    value="Q2"
                                    onChange={(e) => {
                                      setNotifQstatus(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="q2"
                                    className="font-medium text-gray-700"
                                  >
                                    Q2
                                  </label>
                                </div>
                              </div>
                              <div className="flex items-start">
                                <div className="flex h-5 items-center">
                                  <input
                                    id="q5"
                                    name="qstatus"
                                    type="radio"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    value="Q5"
                                    onChange={(e) => {
                                      setNotifQstatus(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="q5"
                                    className="font-medium text-gray-700"
                                  >
                                    Q5
                                  </label>
                                </div>
                              </div>
                              <div className="flex items-start">
                                <div className="flex h-5 items-center">
                                  <input
                                    id="q10"
                                    name="qstatus"
                                    type="radio"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    value="Q10"
                                    onChange={(e) => {
                                      setNotifQstatus(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="q10"
                                    className="font-medium text-gray-700"
                                  >
                                    Q10
                                  </label>
                                </div>
                              </div>
                              <div className="flex items-start">
                                <div className="flex h-5 items-center">
                                  <input
                                    id="q25"
                                    name="qstatus"
                                    type="radio"
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                    value="Q25"
                                    onChange={(e) => {
                                      setNotifQstatus(e.target.value);
                                    }}
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="q25"
                                    className="font-medium text-gray-700"
                                  >
                                    Q25
                                  </label>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                        <div className="col-span-6 mt-4 sm:col-span-4">
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                            onClick={() => {
                              console.log("notif q status", notifQStatus);
                              openModalNotif(
                                getStatus(notifTma),
                                notifTma,
                                notifQStatus
                              );
                            }}
                          >
                            Kirim
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 grid grid-cols-3 gap-4">
          <div className="">
            <ChartData
              sensor="Tinggi_Muka_Air"
              waktu={tWaktu}
              kategori="awlr"
              chartColor={CHART_COLORS.blue}
            />
          </div>

          <div>
            <ChartData
              sensor="Wind_Speed"
              waktu={tWaktu}
              kategori="t_klimatologi"
              chartColor={CHART_COLORS.red}
            />
          </div>

          <div>
            <ChartData
              sensor="Air_Temperature"
              waktu={tWaktu}
              kategori="t_klimatologi"
              chartColor={CHART_COLORS.orange}
            />
          </div>
          <div>
            <ChartData
              sensor="Relative_Humidity"
              waktu={tWaktu}
              kategori="t_klimatologi"
              chartColor={CHART_COLORS.yellow}
            />
          </div>
          <div>
            <ChartData
              sensor="Air_Pressure"
              waktu={tWaktu}
              kategori="t_klimatologi"
              chartColor={CHART_COLORS.green}
            />
          </div>
          <div>
            <ChartData
              sensor="Muka_Air"
              waktu={tWaktu}
              kategori="v_notch"
              chartColor={CHART_COLORS.purple}
            />
          </div>
        </div>

        <div className="mt-4 w-full overflow-hidden rounded-lg bg-white px-8">
          <div className="mt-4 text-lg font-semibold uppercase">Instrument</div>
          <div className="flex flex-wrap gap-4 py-4">
            {instrument.map((d, i) => {
              return (
                <div
                  key={i}
                  className={`cursor-pointer rounded-md border px-4 py-2 text-sm font-semibold shadow-md hover:bg-gray-200 hover:text-black ${
                    activeInstr == null ? "bg-blue-500 text-white" : "bg-white"
                  }`}
                  onClick={() => {
                    // setActiveInstr(i);
                    modalInstrumentRef.current.openModal(d, tWaktu);
                  }}
                >
                  {d}
                </div>
              );
            })}
          </div>
          <img
            src="/images/DENAH-INSTRUMENTASI.jpg"
            alt=""
            className="w-full"
          />
        </div>

        <div className="mt-4 w-full overflow-hidden rounded-lg bg-white">
          <div className="relative w-full">
            <div className="z-10 w-full">
              <div className="flex gap-4 p-4">
                <div className="flex items-center gap-4 rounded-md border bg-white bg-opacity-50 px-4 py-2 shadow-md">
                  <img
                    src="/images/pin_titik_kumpul.png"
                    alt=""
                    className="h-10"
                  />
                  <div className="font-semibold">Titik Kumpul</div>
                </div>
                <div className="flex items-center gap-4 rounded-md border bg-white bg-opacity-50 px-4 py-2 shadow-md">
                  <img
                    src="/images/pin_titik_pengungsian.png"
                    alt=""
                    className="h-10"
                  />
                  <div className="font-semibold">Titik Pengungsian</div>
                </div>
              </div>
            </div>

            <div className="relative z-0 aspect-video">
              <MapContainer
                center={center}
                zoom={13}
                scrollWheelZoom={false}
                style={{ height: "100%" }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <GeoJSON
                  key="titik_kumpul"
                  data={titikKumpul}
                  pointToLayer={(feature, latlng) => {
                    const parkIcon = new L.Icon({
                      iconUrl: "/images/pin_titik_kumpul.png",
                      iconSize: [26, 35],
                      popupAnchor: [0, -15],
                      // shadowUrl: markerShadow,
                      // shadowAnchor: [13, 28],
                    });

                    return L.marker(latlng, {
                      icon: parkIcon,
                    });
                  }}
                  onEachFeature={(feature, layer) => {
                    // console.log("feature", feature);
                    layer.bindPopup(`<p>${feature.properties.KML_FOLDER}</p>`);
                  }}
                />

                <GeoJSON
                  key="titik_pengungsian"
                  data={titikPengunsian}
                  pointToLayer={(feature, latlng) => {
                    const parkIcon = new L.Icon({
                      iconUrl: "/images/pin_titik_pengungsian.png",
                      iconSize: [26, 35],
                      popupAnchor: [0, -15],
                      // shadowUrl: markerShadow,
                      // shadowAnchor: [13, 28],
                    });

                    return L.marker(latlng, {
                      icon: parkIcon,
                    });
                  }}
                  onEachFeature={(feature, layer) => {
                    // console.log("feature", feature);
                    layer.bindPopup(`<p>${feature.properties.NAME}</p>`);
                  }}
                />
              </MapContainer>
            </div>
          </div>
        </div>

        {/* <button onClick={() => refreshToken()}>Refresh Token</button> */}

        {/* <div className="mt-4">
          <div className="grid h-full w-full grid-cols-2 gap-4">
            <div className="rounded-md border bg-white p-4">
              <Line
                data={{
                  labels: [...dataLabel],
                  datasets: [
                    {
                      id: 1,
                      label: 'Tinggi Muka Air',
                      data: [...tinggiMukaAir],
                      borderColor: 'rgb(53, 162, 235)',
                      backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                  ],
                }}
              />
            </div>

            <div className="rounded-md border bg-white p-4">
              <Line
                data={{
                  labels: [...dataLabel],
                  datasets: [
                    {
                      id: 3,
                      label: 'Temprature',
                      data: [...temprature],
                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div> */}
      </div>
      <ModalNotification ref={modalNotifRef} />

      <ModalInstrument ref={modalInstrumentRef} />
    </div>
  );
}

export default Dashboard;
