import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    // console.log('request config', config);
    return config;
  },
  (error) => {
    // Do something with request error
    // console.error('request error', error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log('fullfilled response', response);
    return response;
  },
  async (error) => {
    console.error('response error', error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    const originalRequest = error.config;

    if (originalRequest.url === '/api/auth/refresh') {
      if (
        error.response.data.message === 'refresh token is missing' ||
        error.response.data.message === 'resfresh token is invalid or expired'
      ) {
        console.log('logout');
        setAuthorizationHeader(null);
        if (window.location.pathname !== '/login') {
          window.location.replace('/login');
        }
        return Promise.reject(error);
      }
    } else {
      //refresh token
      if (
        error.response.data.message === 'access token is missing' ||
        error.response.data.message === 'access token is invalid or expired'
      ) {
        console.log('refresh token');
        setAuthorizationHeader(localStorage.getItem('token'));
        // console.log('original req', originalRequest);
        originalRequest.headers.Authorization =
          'Bearer ' + localStorage.getItem('token');
        const res = await instance.get('/api/auth/refresh');
        console.log('response refresh token', res);
        if (res.status === 200) {
          console.log('refresh token', res);
          setAuthorizationHeader(res.data.data.access_token);
          // console.log('original req', originalRequest);
          originalRequest.headers.Authorization =
            'Bearer ' + res.data.data.access_token;
          return axios(originalRequest);
        }
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

const setAuthorizationHeader = (token = null) => {
  if (token) {
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    delete instance.defaults.headers.common['Authorization'];
  }
};

export { setAuthorizationHeader };

export default instance;
