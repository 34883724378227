import axios from "../../config/axios";
import { useEffect, useState } from "react";
import ChartData from "../../component/molecul/ChartData";
import { CHART_COLORS } from "../../config/utils";

function DataVNotch() {
  const [lastData, setLastData] = useState([]);
  const [waktu, setWaktu] = useState("");

  let currrentDate = new Date();
  let tWaktu =
    currrentDate.getFullYear() +
    "-" +
    (currrentDate.getMonth() + 1) +
    "-" +
    currrentDate.getDate();

  useEffect(() => {
    getLastData();
  }, []);

  const getLastData = async () => {
    try {
      const response = await axios.get(
        `/api/telemetri/last?idlogger=10079&kategori=v_notch`
      );
      // console.log('awr', response);
      setWaktu(response.data.data.waktu);
      setLastData(response.data.data.data_terakhir);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="w-full">
      <div className="border-b bg-white p-4 font-bold uppercase">
        DATA V NOTCH
      </div>
      <div className="mx-auto h-full w-full flex-1 p-4">
        <div className="mb-4 rounded-md border bg-white p-4">
          <div className="text-xl font-bold">Data Terakhir {waktu}</div>
          <table className="mt-4">
            <thead>
              {lastData &&
                lastData.length > 0 &&
                lastData.map((d, i) => {
                  return (
                    <tr key={i}>
                      <th className="pr-8 text-left">
                        {d.sensor.replace(/_/g, " ")}
                      </th>
                      <td>{d.data + " " + d.satuan}</td>
                    </tr>
                  );
                })}
            </thead>
          </table>
        </div>
        <ChartData
          sensor="Muka_Air"
          waktu={tWaktu}
          kategori="v_notch"
          chartColor={CHART_COLORS.blue}
        />
      </div>
    </div>
  );
}

export default DataVNotch;
