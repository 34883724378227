/* This example requires Tailwind CSS v2.0+ */
import {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import axios from '../../config/axios';
import useAuth from '../../hook/useAuth';
// import { ExclamationIcon } from '@heroicons/react/outline';

function ModalNotification(props, ref) {
  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const [tma, setTma] = useState(null);
  const [qStatus, setQStatus] = useState(null);
  const [isLoading, setIsloading] = useState(false);

  const cancelButtonRef = useRef(null);

  const openModal = (stat, tm, qstat) => {
    setOpen(true);
    setStatus(stat);
    setTma(tm);
    setQStatus(qstat);
    console.log('q', qstat);
  };

  useImperativeHandle(ref, () => {
    return {
      openModal: openModal,
    };
  });

  const sendNotification = async () => {
    setIsloading(true);
    try {
      // let notif = {
      //   status: status,
      //   tma: tma,
      //   message: 'Tinggin permukaan air saat ini adalah ' + tma + ' Mdpl',
      // };
      // const res = await axios.post('/api/telemetri/notification', notif);
      // console.log('res', res);

      let laporanDarurat = {
        user_id: auth.user.id,
        tma: tma,
        status: qStatus,
      };

      const res = await axios.post('/api/laporan-darurat', laporanDarurat);
      console.log('res', res);
      setOpen(false);
    } catch (error) {
      console.error(error.response);
    }
    setIsloading(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
                      {/* <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      /> */}

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-orange-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Notifikasi
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Apakah anda yakin untuk mengirimkan notifikasi dengan
                          <br></br>status : <b>{status}</b>
                          <br></br>tma : <b>{tma}</b>
                          <br></br> q status : <b>{qStatus}</b> ?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => sendNotification()}
                  >
                    Kirim Notifikasi
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Batal
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default forwardRef(ModalNotification);
