/* eslint-disable react-hooks/exhaustive-deps */
import axios from '../config/axios';
import { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import useSocket from '../hook/useSocket';
import moment from 'moment';
import ChartData from '../component/molecul/ChartData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function TinggiMukaAir() {
  const { label, dataset } = useSocket();
  const [dataLabel, setDataLabel] = useState([]);
  const [dataDataset, setDataDataset] = useState([]);

  useEffect(() => {
    fetchDataAwlr();
  }, []);

  useEffect(() => {
    console.log('data label', dataLabel);
    setDataLabel([...dataLabel, ...label]);
  }, [label]);

  useEffect(() => {
    console.log('dataset', dataset);
    if (dataset.length > 0) {
      let dataSetJson = dataset[0];
      setDataDataset([...dataDataset, dataSetJson[0].data]);
    }
  }, [dataset]);

  const fetchDataAwlr = async () => {
    try {
      const res = await axios.get('/api/telemetri?sensor=Tinggi_Muka_Air');
      console.log('res', res.data.data);
      let awlr = res.data.data;
      let dlabel = [];
      let dset = [];
      for (let index = awlr.length - 1; index >= 0; index--) {
        dlabel = [...dlabel, moment(awlr[index].waktu).format('HH:mm')];

        // setDataLabel([...dataLabel, awlr[index].waktu]);
        // let set = JSON.parse(awlr[index].data);
        // console.log('set', set);
        dset = [...dset, awlr[index].data];
        // setDataDataset([...dataDataset, dset[0].data]);
      }
      console.log(dlabel, dset);
      setDataLabel(dlabel);
      setDataDataset(dset);
    } catch (error) {
      console.error(error.response);
    }
  };

  return (
    <div className="w-full">
      <div className="border-b bg-white p-4 font-bold uppercase">DATA AWLR</div>
      <div className="mx-auto h-full w-full flex-1 p-4">
        {/* <div className="rounded-md border bg-white p-4">
          <Line
            data={{
              labels: [...dataLabel],
              datasets: [
                {
                  id: 1,
                  label: 'Tinggi Muka Air',
                  data: [...dataDataset],
                  borderColor: 'rgb(53, 162, 235)',
                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
              ],
            }}
          />
        </div> */}
        <ChartData sensor="Tinggi_Muka_Air" waktu="2022-08-11" />
      </div>
    </div>
  );
}

export default TinggiMukaAir;
