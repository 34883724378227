function SidebarButton({ children, title, _onClick }) {
  return (
    <div className="group" onClick={() => _onClick()}>
      <div className="relative cursor-pointer rounded-md p-3 text-gray-500 group-hover:bg-slate-100">
        {children}
        <div className="absolute left-16 top-0 z-50 hidden h-full items-center group-hover:flex">
          <div className="whitespace-nowrap rounded-md bg-gray-900 px-3 py-1 text-sm text-white">
            {title}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarButton;
