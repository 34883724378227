import axios from "../../config/axios";
import { useEffect, useState } from "react";
import { getDatabase, onValue, ref } from "firebase/database";
import { app } from "../../config/firebase";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { popup } from "leaflet";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function ChartData(props) {
  const [dataLabel, setDataLabel] = useState([]);
  const [dataDataset, setDataDataset] = useState(["10:01", "10:02"]);

  const db = getDatabase(app);

  useEffect(() => {
    const starCountRef = ref(db, `telemetri/${props.kategori}`);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      console.log("firebase data telemetri", data);

      // setTanggal(data.waktu);
      // setTma(data.data_terakhir[0].data);
      // setTmaAwlrOrong(data.orong.debit.toFixed(2));
      // setTmaAwlrBlencong(data.blencong.debit.toFixed(2));
      // data.data_terakhir.map((d, i) => {
      //   let w = new Date(d.waktu);

      //   let dalab = [...dataLabel]
      //   dalab.push(w)

      //   if (props.sensor === d.sensor) {
      //     // dataDataset
      //   }
      // })

      // let label = [...dataLabel];
      // let dataset = [...dataDataset];

      // for (let i = 0; i < data.data_terakhir.length; i++) {
      //   // console.log('data', data.data_terakhir[i].sensor);
      //   if (props.sensor === data.data_terakhir[i].sensor) {
      //     console.log('sensor', data.data_terakhir[i].sensor);
      //     console.log('data', data.data_terakhir[i].data);
      //     let w = new Date(data.waktu);

      //     if (dataset.length < 20) {
      //       label.pop();
      //       dataset.pop();
      //       label.push(w.getHours() + ':' + w.getMinutes());
      //       dataset.push(data.data_terakhir[i].data);
      //     }
      //   }
      // }
      getData();
    });
  }, [props.kategori, props.sensor]);

  useEffect(() => {
    // getData();
  }, [props.waktu]);

  const getData = async () => {
    try {
      // console.log('waktu', props.waktu);
      const response = await axios.get(
        `/api/telemetri?sensor=${props.sensor}&waktu=${props.waktu}&kategori=${props.kategori}`
      );
      // console.log('chart data', response.data);

      let label = [];
      let dataset = [];

      response.data.data.map((d) => {
        let w = new Date(d.waktu);

        if (dataset.length < 20) {
          label.push(w.getHours() + ":" + w.getMinutes());
          dataset.push(d.data);
        }
      });

      setDataLabel(label.reverse());
      setDataDataset(dataset.reverse());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="rounded-md border bg-white p-4">
      <Line
        data={{
          labels: [...dataLabel],
          datasets: [
            {
              id: 1,
              label: props.sensor.replace(/_/g, " "),
              data: [...dataDataset],
              borderColor: props.chartColor,
              backgroundColor: props.chartColor,
            },
          ],
        }}
      />
    </div>
  );
}

export default ChartData;
