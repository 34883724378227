import { Outlet } from 'react-router-dom';
import SocketProvider from '../../context/SocketContext';
import Footer from '../atom/Footer';
import Sidebar from '../molecul/Sidebar';

function DefaultLayout() {
  return (
    <SocketProvider>
      <div className="flex h-screen">
        <div>
          <Sidebar />
        </div>
        <div className="h-screen flex-1 overflow-scroll">
          <Outlet />
        </div>
      </div>
    </SocketProvider>
  );
}

export default DefaultLayout;
