function DataTeknis() {
  return (
    <div className="flex min-h-screen w-full flex-col">
      <div className="border-b bg-white p-4 font-bold uppercase">
        Data Teknis
      </div>
      <div className="mx-auto h-full w-full flex-1 p-4 md:w-full">
        <div>
          <div className="h-full overflow-hidden rounded-md border bg-white">
            <div className="border-b bg-white p-4 font-bold uppercase">
              Informasi Bendungan
            </div>
            <div className="p-4">
              <div className="text-2xl font-semibold uppercase">
                Bendungan Bintang Bano
              </div>
              <div className="font-semibold uppercase text-gray-500">
                Data Teknis Bendungan
              </div>

              <div className="mt-8 font-semibold uppercase text-gray-900">
                Daerah Aliran Sungai
              </div>
              <div className="mt-2 overflow-hidden rounded-md border">
                <table className=" w-full">
                  <tbody className="divide-y">
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Luas Daerah Tangkapan Air
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        212 Km<sup>2</sup>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Panjang Sungai
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        212 Km<sup>2</sup>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mt-8 font-semibold uppercase text-gray-900">
                Bendungan Utama (Main Dam)
              </div>
              <div className="mt-2 overflow-hidden rounded-md border">
                <table className=" w-full">
                  <tbody className="divide-y">
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Tipe
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        Rockfill dengan initi tegak material lempung
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Panjang Puncak
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        471,4 m
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Lebar Puncak
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        12,0 m
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Elevasi Puncak
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        EL +120,0 m
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Elevasi Dasar Sungai
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        EL +56,0 m
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Kemiringan Lereng
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium"></td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Hulu
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        1:2,2
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Hilir
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        1:1,80
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mt-8 font-semibold uppercase text-gray-900">
                Daerah Genangan
              </div>
              <div className="mt-2 overflow-hidden rounded-md border">
                <table className=" w-full">
                  <tbody className="divide-y">
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Luas Daerah Genangan
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        277,52 Ha
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Volume Tampung Waduk
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        69,0 juta m<sup>2</sup>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Volume Tampung Mati
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        11,21 juta m<sup>2</sup>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Elevasi Muka Air Normal
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        EL +115,5 m
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Elevasi Muka Air Banjir
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        EL +118,25 m
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Elevasi Muka Air Banjir
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        EL +84,1 m
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="mt-8 font-semibold uppercase text-gray-900">
                Spillway
              </div>
              <div className="mt-2 overflow-hidden rounded-md border">
                <table className=" w-full">
                  <tbody className="divide-y">
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Pintu Spillway Type Radial
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        2,00 buah (L = 8,00 m, H = 13,00)
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Pintu Stoplog Spillway
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        7,00 buah (L = 8,00 m, H = 1,40 m)
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Gantry Crane dengan Rell dan Hoist
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        1,00 Unit
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Generator set 150 KVA
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        2,00 Unit
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Jembatan Pratekan
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        1,00 buah (P = 25,00 m, L = 5,00 m)
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Jembatan Blok T
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        2,00 buah (P = 9,00 m, L = 5,00 m)
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Jembatan pelimpah berpintu
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        2,00 buah (P = 9,00 m, L = 2,00 m)
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Panjang saluran transisi
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        75,00 m
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Panjang saluran peluncur
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        115,00 m
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Side spillway type ogee crest
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        1,00 buah
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Panjang Ambang
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        60,00 m
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Panjang Ambang
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        60,00 m
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Lebar chuteway
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        20,00 m
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Panjang saluran transisi
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        78,87 m
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Panjang stilling basin
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        40,00 m
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3 py-2 text-sm font-medium text-gray-500">
                        Lebar stilling basin
                      </td>
                      <td className="px-3 py-2 text-right text-sm font-medium">
                        32,00 m
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataTeknis;
