import React from "react";
import { Route, Routes } from "react-router-dom";
import DefaultLayout from "./component/layout/DefaultLayout";
import AuthProvider from "./context/AuthContext";
import RequireAuth from "./middleware/RequireAuth";
import Batteray from "./pages/Batteray";
import Dashboard from "./pages/Dashboard";
import DataAvwr from "./pages/DataAvwr";
import DataAwlrAll from "./pages/DataAwlrAll";
import DataTelemetri from "./pages/DataTelemetri";
import DataTeknis from "./pages/DateTeknis";
import Humidity from "./pages/Humidity";
import Login from "./pages/Login";
import Map from "./pages/Map";
import DataArr from "./pages/telemetri/DataArr";
import DataAvw from "./pages/telemetri/DataAvw";
import DataAwlr from "./pages/telemetri/DataAwlr";
import DataAwr from "./pages/telemetri/DataAwr";
import DataVNotch from "./pages/telemetri/DataVNotch";
import Temprature from "./pages/Temprature";
import TinggiMukaAir from "./pages/TinggiMukaAir";
import Users from "./pages/Users";

function App() {
  return (
    <AuthProvider>
      <div className="h-screen w-full bg-gray-100">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <RequireAuth>
                <DefaultLayout />
              </RequireAuth>
            }
          >
            <Route index path="" element={<Dashboard />} />
            <Route path="map" element={<Map />} />
            <Route path="telemetri" element={<DataTelemetri />}>
              {/* <Route path="all" element={<DataAwlrAll />} /> */}
              <Route path="awlr" element={<DataAwlr />} />
              <Route path="awr" element={<DataAwr />} />
              <Route path="arr" element={<DataArr />} />
              <Route path="avwr" element={<DataAvw />} />
              <Route path="v-notch" element={<DataVNotch />} />

              <Route path="humidity" element={<Humidity />} />
              <Route path="temprature" element={<Temprature />} />
              <Route path="batteray" element={<Batteray />} />
            </Route>
            <Route path="data-teknis" element={<DataTeknis />} />
            <Route path="users" element={<Users />} />
          </Route>
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
