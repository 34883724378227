/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

export const SocketContext = createContext();

function SocketProvider({ children }) {
  const [socket, setSocket] = useState(null);
  const [label, setLabel] = useState([]);
  const [dataset, setDataset] = useState([]);

  useEffect(() => {
    console.log('init socket');
    const newScoket = io(process.env.REACT_APP_SOCKET_SERVER, {transports: ['websocket'],});
    setSocket(newScoket);
    newScoket.emit('connected', 'from frontend');
    newScoket.on('awlr', (msg) => {
      console.log('awlr', msg);
      const dl = [...label];
      setLabel([...dl, msg.waktu]);
      setDataset([...dataset, msg.data]);
    });

    newScoket.on('updated-data', (msg) => {
      console.log('msg', msg);
    });

    return () => newScoket.close();
  }, [setSocket]);

  // useEffect(() => {
  //   if (socket) {
  //     socket.on('awlr', (msg) => {
  //       console.log('awlr', msg);
  //       const dlabel = [...label];
  //       dlabel.push('test');
  //       // console.log('data label', dlabel);
  //       setLabel(msg.waktu);
  //     });
  //   }
  // }, [socket]);

  useEffect(() => {
    console.log('label', label);
  }, [label]);

  useEffect(() => {
    console.log('dataset', dataset);
  }, [dataset]);

  const value = {
    socket,
    label,
    dataset,
  };

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
}

export default SocketProvider;
