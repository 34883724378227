import axios from '../config/axios';
import { useEffect, useState } from 'react';

function Users() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const res = await axios.get('/api/users');
      console.log('users', res);
      setUsers(res.data.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="flex min-h-screen w-full flex-col">
      <div className="border-b bg-white p-4 font-bold uppercase">
        Daftar Users
      </div>
      <div className="mx-auto h-full w-full flex-1 p-4 md:w-full">
        <div>
          <div className="h-full overflow-hidden rounded-md border bg-white">
            <div className="border-b bg-white p-4 font-bold uppercase">
              Informasi Daftar Users
            </div>
            <div className="p-4">
              <div className="w-full rounded-md border">
                <table className="w-full">
                  <thead>
                    <tr className="">
                      <th className="w-10 px-4 py-2">No.</th>
                      <th className="px-4 py-2 text-left">Nama</th>
                      <th className="px-4 py-2 text-left">Username</th>
                      <th className="px-4 py-2 text-left">Jabatan</th>
                      <th className="px-4 py-2 text-left">HP</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y border-t">
                    {users.map((d, i) => {
                      return (
                        <tr key={i}>
                          <td className="px-4 py-2 text-center text-sm">
                            {i + 1}
                          </td>
                          <td className="px-4 py-2 text-sm">{d.name}</td>
                          <td className="px-4 py-2 text-sm">{d.username}</td>
                          <td className="px-4 py-2 text-sm">{d.jabatan}</td>
                          <td className="px-4 py-2 text-sm">{d.phone}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Users;
