import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hook/useAuth';

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (auth.user === '') {
    return (
      <div className="flex min-h-screen w-full items-center justify-center">
        <div className="flex flex-col items-center justify-center">
          <div>
            <span className="loader"></span>
          </div>
          <div className="mt-4 font-medium">Please wait...</div>
        </div>
      </div>
    );
  }

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
