import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { useState } from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function DataAvwr() {
  const [dataLabel, setDataLabel] = useState([]);
  const [dataDataset, setDataDataset] = useState([]);
  const [label, setLabel] = useState('');

  const renderButtonMenu = () => {
    let item = [];

    for (let index = 0; index < 12; index++) {
      item.push(
        <div
          className="cursor-pointer rounded-lg border bg-white px-6 py-2 hover:bg-blue-600 hover:text-white"
          onClick={() => setLabel('SP' + (index + 1))}
        >
          SP {index + 1}
        </div>
      );
    }

    return item;
  };

  return (
    <div className="w-full">
      <div className="border-b bg-white p-4 font-bold uppercase">DATA AVWR</div>

      {/* {renderButtonMenu.map((d, i) => {
        return <div key={i}>{d}</div>;
      })} */}

      <div className="mx-auto h-full w-full flex-1 p-4">
        <div className="flex gap-1">{renderButtonMenu()}</div>
        <div className="mt-4 rounded-lg border bg-white p-4">
          <Line
            data={{
              labels: [...dataLabel],
              datasets: [
                {
                  id: 1,
                  label: label,
                  data: [...dataDataset],
                  borderColor: 'rgb(53, 162, 235)',
                  backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
              ],
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default DataAvwr;
