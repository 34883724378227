import axios, { setAuthorizationHeader } from '../config/axios';
import { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState('');

  useEffect(() => {
    me();
  }, []);

  const me = async () => {
    try {
      const res = await axios.get('/api/auth/me');
      console.log('res', res);
      setUser(res.data.data.user);
    } catch (error) {
      console.error(error.response);
      setUser(false);
    }
  };

  const login = async (credentials) => {
    try {
      const res = await axios.post('/api/auth/login', credentials, {
        withCredentials: true,
      });
      console.log('login', res.data.data.access_token);
      setAuthorizationHeader(res.data.data.access_token);

      localStorage.setItem('token', res.data.data.refresh_token);

      await me();
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const value = {
    user,
    setUser,
    login,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
