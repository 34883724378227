import axios from "../../config/axios";
import ChartData from "../../component/molecul/ChartData";
import { useEffect, useState } from "react";
import { CHART_COLORS } from "../../config/utils";
import ChartDataInstrument from "../../component/molecul/ChartDataInstrument";

function DataAvw() {
  const instrument = [
    "FP09",
    "FP10",
    "FP11",
    "FP12",
    "FP13",
    "FP14",
    "FP15",
    "FP16",
    "EP10",
    "EP11",
    "EP12",
    "EP13",
    "EP14",
    "EP15",
    "EP16",
    "EP17",
    "EP18",
  ];

  const [activeInstr, setActiveInstr] = useState(0);

  let currrentDate = new Date();
  let tWaktu =
    currrentDate.getFullYear() +
    "-" +
    (currrentDate.getMonth() + 1) +
    "-" +
    currrentDate.getDate();

  useEffect(() => {
    getLastData();
  }, []);

  const getLastData = async () => {
    try {
      // let currrentDate = new Date();
      // let tWaktu =
      //   currrentDate.getFullYear() +
      //   '-' +
      //   (currrentDate.getMonth() + 1) +
      //   '-' +
      //   currrentDate.getDate();
      // setWaktu(tWaktu);
      //   const response = await axios.get(
      //     `/api/telemetri?sensor=${instrument[activeInstr]}&waktu=${tWaktu}&kategori=avw`
      //   );
      //   console.log("avw", response);
      //   setWaktu(response.data.data.waktu);
      //   setLastData(response.data.data.data_terakhir);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="w-full">
      <div className="border-b bg-white p-4 font-bold uppercase">DATA AVW</div>
      <div className="mx-auto h-full w-full flex-1 p-4">
        <div className="mb-4 rounded-md border bg-white p-4">
          <div className="flex flex-wrap gap-4 py-4">
            {instrument.map((d, i) => {
              return (
                <div
                  key={i}
                  className={`cursor-pointer rounded-md border px-4 py-2 text-sm font-semibold shadow-md hover:bg-gray-200 hover:text-black ${
                    activeInstr == i ? "bg-blue-500 text-white" : "bg-white"
                  }`}
                  onClick={() => {
                    setActiveInstr(i);
                  }}
                >
                  {d}
                </div>
              );
            })}
          </div>
        </div>
        <div className="">
          <ChartDataInstrument
            sensor={instrument[activeInstr]}
            waktu={tWaktu}
            kategori="avw"
          />
        </div>
      </div>
    </div>
  );
}

export default DataAvw;
