// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAM-Uw6P_SKIwaCHmo2pJzzC9MJKWr5QPY',
  authDomain: 'bintang-bano.firebaseapp.com',
  databaseURL:
    'https://bintang-bano-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'bintang-bano',
  storageBucket: 'bintang-bano.appspot.com',
  messagingSenderId: '215494328783',
  appId: '1:215494328783:web:178cd2d5d20ac58a3512ba',
  measurementId: 'G-E1XCW2XFXW',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
