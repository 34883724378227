import axios from "../../config/axios";
import { useEffect, useState } from "react";
import { getDatabase, onValue, ref } from "firebase/database";
import { app } from "../../config/firebase";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { popup } from "leaflet";
import { CHART_COLORS } from "../../config/utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function ChartDataInstrument(props) {
  const [dataLabel, setDataLabel] = useState([]);
  const [dataDataset, setDataDataset] = useState({
    labels: [],
    datasets: [],
  });

  const db = getDatabase(app);

  const options = {
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: "Chart.js Line Chart - Multi Axis",
      },
    },
    scales: {
      y1: {
        type: "linear",
        display: true,
        position: "left",
      },
      y2: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: labels.map(() => Math.random(1000)),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y",
      },
      {
        label: "Dataset 2",
        data: labels.map(() => Math.random(1000)),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        yAxisID: "y1",
      },
    ],
  };

  useEffect(() => {
    const starCountRef = ref(db, `telemetri/${props.kategori}`);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      console.log("firebase data telemetri", data);

      // setTanggal(data.waktu);
      // setTma(data.data_terakhir[0].data);
      // setTmaAwlrOrong(data.orong.debit.toFixed(2));
      // setTmaAwlrBlencong(data.blencong.debit.toFixed(2));
      // data.data_terakhir.map((d, i) => {
      //   let w = new Date(d.waktu);

      //   let dalab = [...dataLabel]
      //   dalab.push(w)

      //   if (props.sensor === d.sensor) {
      //     // dataDataset
      //   }
      // })

      // let label = [...dataLabel];
      // let dataset = [...dataDataset];

      // for (let i = 0; i < data.data_terakhir.length; i++) {
      //   // console.log('data', data.data_terakhir[i].sensor);
      //   if (props.sensor === data.data_terakhir[i].sensor) {
      //     console.log('sensor', data.data_terakhir[i].sensor);
      //     console.log('data', data.data_terakhir[i].data);
      //     let w = new Date(data.waktu);

      //     if (dataset.length < 20) {
      //       label.pop();
      //       dataset.pop();
      //       label.push(w.getHours() + ':' + w.getMinutes());
      //       dataset.push(data.data_terakhir[i].data);
      //     }
      //   }
      // }
      getData();
    });
  }, [props.kategori, props.sensor]);

  useEffect(() => {
    // getData();
  }, [props.waktu]);

  useEffect(() => {
    console.log(dataDataset);
  }, [dataDataset]);

  const getData = async () => {
    try {
      // console.log('waktu', props.waktu);
      const response = await axios.get(
        `/api/telemetri?sensor=${props.sensor}&waktu=${props.waktu}&kategori=${props.kategori}`
      );
      // console.log('chart data', response.data);
      console.log("avw", response);

      let label = [];
      let dataset = [
        {
          label: "Frekuensi",
          data: [],
          borderColor: CHART_COLORS.red,
          backgroundColor: CHART_COLORS.red,
        },
        {
          label: "P(Kgf/cm²)",
          data: [],
          borderColor: CHART_COLORS.green,
          backgroundColor: CHART_COLORS.green,
        },
        {
          label: "P(Kgf/cm²)",
          data: [],
          borderColor: CHART_COLORS.blue,
          backgroundColor: CHART_COLORS.blue,
        },
        {
          label: "Elevasi",
          data: [],
          borderColor: CHART_COLORS.orange,
          backgroundColor: CHART_COLORS.orange,
        },
        {
          label: "Temperature",
          data: [],
          borderColor: CHART_COLORS.purple,
          backgroundColor: CHART_COLORS.purple,
        },
      ];

      let frekData = [];

      response.data.data.map((d) => {
        // for (let i = 0; i < dataset.length; i++) {
        //   console.log("label", dataset[i].label);
        if (dataset[0].label == d.parameter) {
          dataset[0] = {
            ...dataset[0],
            data: [...dataset[0].data, d.data],

            // yAxisID: `y1`,
          };
          let w = new Date(d.waktu);
          label.push(w.getHours() + ":" + w.getMinutes());
        } else if (dataset[1].label == d.parameter) {
          dataset[1] = {
            ...dataset[1],
            data: [...dataset[1].data, d.data],

            // yAxisID: `y2`,
          };
        } else if (dataset[2].label == d.parameter) {
          dataset[2] = {
            ...dataset[2],
            data: [...dataset[2].data, d.data],

            // yAxisID: `y2`,
          };
        } else if (dataset[3].label == d.parameter) {
          dataset[3] = {
            ...dataset[3],
            data: [...dataset[3].data, d.data],

            // yAxisID: `y2`,
          };
        } else if (dataset[4].label == d.parameter) {
          dataset[4] = {
            ...dataset[4],
            data: [...dataset[4].data, d.data],

            // yAxisID: `y2`,
          };
        }
        // }

        // dataset.push(data);
      });

      //   response.data.data.map((d) => {
      //     let w = new Date(d.waktu);

      //     if (dataset.length < 20) {
      //       label.push(w.getHours() + ":" + w.getMinutes());
      //       dataset.push(d.data);
      //     }
      //   });

      //   setDataLabel(label.reverse());
      setDataDataset({
        labels: label,
        datasets: dataset,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="rounded-md border bg-white p-4">
      <Line data={dataDataset} />
    </div>
  );
}

export default ChartDataInstrument;
